import {useHistory } from 'react-router-dom';

import {Container, Row, Col} from 'react-bootstrap';



import './index.css';





function Prizes() {
  const history = useHistory();



  

  return (



    <Container className="white-bg pb-4 prizes">
      <img className="close" onClick={history.goBack} src={process.env.PUBLIC_URL +"/img/close.svg"} alt="close" />
      <Row className="justify-content-center">
        <Col className="col-12 col-xl-6 text-black">
          <h1 className="fw-black lh-1 text-gold-gradient display-3 text-center mt-4">Nagrade</h1>
          <p className="lead text-center my-5">S prijavo se uvrstite v boben za tedenski žreb. Vsak teden bomo izžrebali zmagovalce tedna.</p>
          <p className="lead text-center my-5">Če boste uspešno odklenili več kot 10 dnevnih skrivnosti, pa se uvrstite še v boben za žreb glavne nagrade.</p>

        </Col>
      </Row>

      <Row className="justify-content-center mt-5">
        <Col className="col-12 col-xl-7 text-black">

          <Row>
            <Col className="col-12 col-md-5 text-center">
              <img src={process.env.PUBLIC_URL +"/img/nagrade/tedenske_nagrade.png"} alt="tedenske nagrade" />
              <p className="text-center mt-2"><strong>Vsak teden 7x LIDL VREDNOSTNA KARTICA v vrednosti <span class="text-gold-gradient">10 €</span></strong></p>
            </Col>
            <Col className="col-12 col-md-7">

<h2 class="text-gold-gradient fw-black">Tedenske nagrade</h2>
<p>Vsak teden v ponedeljek bomo izžrebali sedem (7) tedenskih nagrajencev preteklega tedna. V bobnu za žreb sodelujejo vsi, ki so vsaj 1x v preteklem tednu odkrili skrivnost dneva, vpisali svoje podatke in se strinjali s sodelovanjem v nagradni igri. </p>

            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="col-12 col-md-5 text-center">
              <img src={process.env.PUBLIC_URL +"/img/nagrade/glavne_nagrade.png"} alt="glavne nagrade" />
              <p className="text-center mt-2"><strong>5x LIDL VREDNOSTNA KARTICA v vrednosti <span class="text-red-gradient">50 €</span></strong></p>
            </Col>
            <Col className="col-12 col-md-7">

<h2 class="text-red-gradient fw-black">Glavne nagrade</h2>
<p>Po zaključku decembrskega koledarja (24. 12. 2021) bomo med vsemi, ki bodo vsaj desetkrat sodelovali v igri decembrski koledar (z enakim email naslovom) in odklenili vsaj 10 dnevnih skrivnosti izžrebali pet (5) nagrajencev.</p>
<br /><br /><br /><br /><br />
<p>Pravila in pogoje nagradne igre najdete <a target="_blank" rel="noreferrer" href={process.env.PUBLIC_URL +"/files/Pravila_in_Pogoji_Lidl_koledar.pdf"}><strong>tule</strong></a>.</p>
            </Col>
          </Row>


        </Col>
      </Row>

    </Container>



  )

}

export default Prizes;
