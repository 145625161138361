import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {Container, Row, Col, Button, Image, Overlay, Tooltip, Form, Ratio} from 'react-bootstrap';
import Confetti from 'react-dom-confetti';
import { getGameOnDayTEMP } from '../api/ApiCalls.js'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import winSound from '../assets/sounds/win_stage_igra.mp3';
import moveSound from '../assets/sounds/bite.mp3';
import wrongSound from '../assets/sounds/disable-sound.mp3';
import correctSound from '../assets/sounds/rising-pops.mp3';
import ReactGA from 'react-ga';

var dateVariable = new Date();
var todayDay = dateVariable.getDate();



function PuzzleWord() {
  const { search } = useLocation();
  let history = useHistory();



  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [answer, setAnswer] = useState("");
  const [correct, setCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const target = useRef(null);
  

  const audioWin = new Audio(winSound)
  const audioMove = new Audio(moveSound)
  const audioWrong = new Audio(wrongSound)
  const audioCorrect = new Audio(correctSound)


  useEffect(() => {
    const dayParam = new URLSearchParams(search).get("day");
    setIsLoading(true);
    var config = {params: {day: dayParam}}
    getGameOnDayTEMP(config, (res) => {
      setData(res.data);

      setIsLoading(false);
    },(err) => {
        //error
        console.log(err)
    });

  }, []);

  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 70,
    elementCount:100,
    dragFriction: 0.10,
    duration: 2000,
    stagger: 1,
    width: "15px",
    height: "15px",
    perspective: "500px",
    colors: ["#AD8B44", "#A68A4D", "#EB193A", "#AC1129", "#AC1129"]
  };




  const handleSubmitUpdate = (event) => {
    event.preventDefault();
    //const form = event.currentTarget;

    let dataUppercase = data.answerText.map(function (e) { 
        return e.toUpperCase()
    });



    if (!dataUppercase.includes(answer.toUpperCase())) {
      event.preventDefault();
      event.stopPropagation();
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 1500);

      audioWrong.play()


    } else {

      setCorrect(true);

      audioWin.play()
      ReactGA.event({
        category: 'Igra',
        action: 'win',
        label: 'dan-'+todayDay
      });
      setTimeout(() => {
        history.push('/subscribe')
      }, 2000);


    }


  
  };

    


  return (


      <>
      {isLoading ? (
          <div className="text-center my-5"><Loader
          type="TailSpin"
          color="#D9B76B"
          height={100}
          width={100}
          timeout={300} //3 secs
        /></div>
      ) : (
      <Container className="white-bg position-relative pb-4">

        <Row className="px-3 justify-content-center">
          <Col className="col-12 col-lg-10 col-xl-6 px-2 text-center text-black">
            <h1 className="fw-black lh-1 text-gold-gradient display-3">{data.xtratext}</h1>
            <p className="text-center fs-5">{data.xtratext2}</p>


              <>
                <div className='question-section puzzleword mt-4'>

                  <p className='question-text text-center fw-bolder fs-4'>{data.questionText}</p>


                  {data.video ? (


                  <div className="video">
                    <Ratio aspectRatio="16x9">
                      <video controls >
                            <source src={process.env.PUBLIC_URL +"/img/days/"+data.gamePic} type="video/mp4"/>
                      </video>
                    </Ratio>
                    <a className="mt-3" href="https://www.instagram.com/znakovnijezik/" target="_blank">Video @znakovnijezik</a>
                  </div>
                  


                  ) : (


                  <Image className="w-100 mb-2" src={process.env.PUBLIC_URL +"/img/days/"+data.gamePic} />

                  )}








                  
                </div>
                <div className='answer-section'>
                  <Row>


                    <Col className="col-12 px-5 mt-4">

                      <p className="lead fw-bolder">V polje vpiši pravilen odgovor:</p>

                      <Form className="text-center" onSubmit={handleSubmitUpdate}>
                        <Form.Group className="mb-3" controlId="formAnswer">
                          <Form.Control autoFocus className="redInput" type="text" autocomplete="off" onChange={(event) => {setAnswer(event.target.value);}} />
                        </Form.Group>


                        <Button 
                        type="submit"
                        className={"w-50 btn-answer btn-gradient-gold fw-bolder text-white "}
                        size="lg"
                        ref={target}
                        >
                        POTRDI
                        
                        </Button>

                        <Overlay target={target.current} show={show} placement="top">
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              Napačen odgovor<br />Poskusi še enkrat.
                            </Tooltip>
                          )}
                        </Overlay>
                        <Confetti className="confetti" active={ correct } config={ config }/>

                      </Form>




                    </Col>

  

                  </Row>
                </div>
              </>




          </Col>
        </Row>


        <img alt="BG1" className="bg-dec bg-dec__topleft" src={process.env.PUBLIC_URL +"/img/bg_top_left.svg"} />
        <img alt="BG2" className="bg-dec bg-dec__topright" src={process.env.PUBLIC_URL +"/img/bg_top_right.svg"} />
        <img alt="BG3" className="bg-dec bg-dec__bottomleft" src={process.env.PUBLIC_URL +"/img/bg_bottom_left.svg"} />
        <img alt="BG4" className="bg-dec bg-dec__bottomright" src={process.env.PUBLIC_URL +"/img/bg_bottom_right.svg"} />

        <img alt="BG5" className="bg-dec bg-dec__mobile" src={process.env.PUBLIC_URL +"/img/bg_top_mobile.svg"} />


        </Container>
        )}
        </>



  );

}

export default PuzzleWord;
