import axios from "axios";
var dateVariable = new Date();
var todayDay = dateVariable.getDate();
const queryString = require("query-string");

//https://lidl-koledar.escapebox.si/getGame?day=
/*****************/
/* TEMP FUNCTIONS */
/*****************/

let params = new URLSearchParams(window.location.search);
let day = params.get("day"); // 123
console.log(day);

export function getGameOnDayTEMP(config, callback, errorcallback) {
  let url = "https://lidl-koledar.escapebox.si/getGame?day=";

  axios
    .get(url, config)
    .then((res) => {
      //do something
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      console.log(err);
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

/*************************************************************/

/*
let urlGame = 'https://lidl-koledar.escapebox.si/getGame?day='+todayDay
export function getGameOnDayTEMP(config, callback, errorcallback){
    axios.get(urlGame, config)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}
*/

let urlGames = "https://lidl-koledar.escapebox.si/getGames";
export function getGames(config, callback, errorcallback) {
  axios
    .get(urlGames, config)
    .then((res) => {
      //do something
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
