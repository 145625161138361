import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { getGameOnDayTEMP } from '../api/ApiCalls.js'
//import {genDeck} from './';

import Card from '../components/MemoryGame/Card';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";




import winSound from '../assets/sounds/win_stage_igra.mp3';
import moveSound from '../assets/sounds/bite.mp3';
import wrongSound from '../assets/sounds/disable-sound.mp3';
import correctSound from '../assets/sounds/rising-pops.mp3';





function useGame(bg) {

  const { search } = useLocation();


  const [data, setData] = useState([]);
  const [deck, setDeck] = useState(genDeck());
  const [errors, setErrors] = useState(0);
  const [matches, setMatch] = useState([]);
  const [flipping, setFlip] = useState({i:[],v:[]});
  const [isLoading, setIsLoading] = useState(false);

  const audioWin = new Audio(winSound)
  const audioMove = new Audio(moveSound)
  const audioWrong = new Audio(wrongSound)
  const audioCorrect = new Audio(correctSound)

  

  useEffect(() => {
    if (flipping.i.length === 2) {
      checkFlip(flipping);
    }
  }, [flipping]);





  useEffect(() => {
    const dayParam = new URLSearchParams(search).get("day");
    setIsLoading(true);
    var config = {params: {day: dayParam}}
    getGameOnDayTEMP(config, (res) => {


      var arr = res.data.gamePics.map(function (i){
          return "https://escapebox.si/temp/" +i;
      })

      let duplicated = arr.concat([...arr]);
      // there might be better ways of doing this
      let shuffled = duplicated.sort(() => Math.random() - 0.5);


      setData(shuffled);
      setIsLoading(false);
    },(err) => {
        //error
        console.log(err)
    });

  }, []);




  function genDeck() {
    const items = ["https://via.placeholder.com/400x400?text=01","https://via.placeholder.com/400x400?text=02","https://via.placeholder.com/400x400?text=03","https://via.placeholder.com/400x400?text=04","https://via.placeholder.com/400x400?text=05","https://via.placeholder.com/400x400?text=06"]
    

/*
    var arr = data.map(function (i){
        return "https://escapebox.si/temp/" +i;
    })
*/


    // better than looping
    let duplicated = items.concat([...items]);
    // there might be better ways of doing this
    let shuffled = duplicated.sort(() => Math.random() - 0.5);


    return shuffled;
  }



  function checkFlip(f) {
    if (f.v[0] === f.v[1]) {
      audioCorrect.play()
      setMatch([...matches, f.v[0]]);
      setFlip({i:[], v:[]});
    } else {
      setErrors(() => errors + 1);
      

      setTimeout(() => {
        audioWrong.play()
      }, 150);

      setTimeout(() => {
        setFlip({i:[], v:[]});
      }, 1500);
    }
  }

  function reshuffle() {

    setFlip({i:[], v:[]});
    setMatch([]);
    setErrors(0);
    setTimeout(() => setDeck(data), 600);
  }
  
  function cardClick(value, index) {
    const {i, v} = flipping;
    if (i.length < 2) {

      setFlip({i:[...i, index],v:[...v, value]});
    }

    audioMove.play()
  }




  

  const cards = data.map((value, i) => (

    <Card key={i} bg={bg}
      onClick={() => cardClick(value, i)}
      value={value} 
      matched={matches.includes(value)}
      flipping={flipping.i.includes(i)}
    />
  ));





  
  const finished = matches.length === deck.length / 2;

  return [cards, reshuffle, errors, finished];
}

export default useGame;
