import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, useLocation } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import Advent from "./components/Advent";
import Subscribe from "./components/Subscribe";
import Quiz from "./components/Quiz";
import Memory from "./components/MemoryGame/MemoryGame";
import Puzzle from "./components/Puzzle";
import PuzzleWord from "./components/PuzzleWord";
import ClickAndFind from "./components/ClickAndFind";
import Thankyou from "./components/ThankYou";
import Prizes from "./components/Prizes";
import ScrollToTop from "./components/ScrollToTop";
import useSound from 'use-sound';

import music from './assets/sounds/background_song.mp3';


import ReactGA from 'react-ga';


import usePageTracking from "./components/usePageTracking";



const routeAnimation = {
  atEnter: {
    opacity: 0,
    translateY: -100,
  },
  atLeave: {
    opacity: 0,
    translateY: 100,
  },
  atActive: {
    opacity: 1,
    translateY: 0,
  }
}

const mapStyles = (styles) => {
  return {
    opacity: styles.opacity,
    transform: `translateY(${styles.translateY}px)`,
  };
}


const useAudio = () => {

  const [audio] = useState(new Audio(music, { volume: 0.5 }));
  const [playing, setPlaying] = useState(false);

  const toggleSound = (e) => {
    e.preventDefault();
    setPlaying(!playing);
    ReactGA.event({
      category: 'CTA',
      action: 'zvok'
    });


  }

  useEffect(() => {

      playing ? audio.play() : audio.pause();

    },
    [playing]
  );




  return [playing, toggleSound];
};


const trackPrizes = () => {
  ReactGA.event({
    category: 'CTA',
    action: 'nagrade'
    });
}



function App() {

    usePageTracking();
    const [playing, toggleSound] = useAudio(music);

    return (



          <>
            <ScrollToTop />
            <div class="header-actions">
            <Link to="/prizes"><img onClick={trackPrizes} className="icon-nagrade" alt="nagrade" src={process.env.PUBLIC_URL +"/img/nagrade.svg"} /></Link>
            <Link to="/prizes"><img onClick={toggleSound} className="icon-sound" alt="nagrade" src={process.env.PUBLIC_URL + (playing ? "/img/zvok-da.svg" : "/img/zvok-ne.svg")} /></Link>
            </div>
            <AnimatedSwitch
              atEnter={routeAnimation.atEnter}
              atLeave={routeAnimation.atLeave}
              atActive={routeAnimation.atActive}
              mapStyles={mapStyles}
              className="switch-wrapper"
            >
              <Route exact path="/">
                <Advent />
              </Route>
              <Route exact path="/subscribe">
                <Subscribe />
              </Route>
              <Route path="/quiz">
                <Quiz />
              </Route>
              <Route path="/memory">
                <Memory />
              </Route>
              <Route path="/puzzle">
                <Puzzle />
              </Route>
              <Route path="/clickandfind">
                <ClickAndFind />
              </Route>
              <Route path="/thankyou">
                <Thankyou />
              </Route>
              <Route path="/prizes">
                <Prizes />
              </Route>
              <Route path="/puzzleword">
                <PuzzleWord />
              </Route>
            </AnimatedSwitch>
          </>






    )

}

export default App;
