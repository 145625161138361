import { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Container, Row, Col } from "react-bootstrap";
import Snowfall from "react-snowfall";
import SVG from "react-inlinesvg";
import { ReactComponent as Icon1 } from "../assets/img/icons/usp_01.svg";
import { ReactComponent as Icon2 } from "../assets/img/icons/usp_02.svg";
import { ReactComponent as Icon3 } from "../assets/img/icons/usp_03.svg";
import openSound from "../assets/sounds/odpri_okno_koledarja.mp3";
import { getGameOnDayTEMP, getGames } from "../api/ApiCalls.js";
import ReactGA from "react-ga";

function Advent() {
  const [listOfGames, setListOfGames] = useState([]);
  const [data, setData] = useState([]);
  const [participation, setParticipation] = useState([]);

  const cookies = new Cookies();
  const hasCookie = cookies.get("user");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    var config = {};
    getGames(
      config,
      (res) => {
        setListOfGames(res.data);
        setIsLoading(false);
        console.log("data", res.data);
      },
      (err) => {
        //error
        alert(err);
      }
    );
  }, []);

  /////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////

  // Filter by saved cookie
  useEffect(() => {
    if (hasCookie) {
      Axios.get(
        "https://lidl-koledar.escapebox.si/getUsers?email=" + hasCookie
      ).then((response) => {
        if (
          response.data[0] !== undefined &&
          hasCookie == response.data[0].email
        ) {
          setParticipation(response.data[0].day);
        } else {
          //else..
        }
      });
    }
  }, []);

  const audioOpen = new Audio(openSound);

  const duration = 1000; // ms
  const delay = 100; // ms

  const animStr = (i) =>
    `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards `;

  var dateVariable = new Date();
  //var yesterdayDay =  parseInt(data.day, 10) - 1; //dateVariable.getDate()-1;
  var todayDay = dateVariable.getDate(); //dateVariable.getDate();
  //var tomorrowDay = parseInt(data.day, 10) + 1; //dateVariable.getDate()+1;

  const start = () => {
    setTimeout(function () {
      audioOpen.play();
    }, 100);

    ReactGA.event({
      category: "CTA",
      action: "okno",
      label: "dan-" + todayDay,
    });
  };

  const ShowCalendar = ({ games, index }) => {
    let dayImage = process.env.PUBLIC_URL + "/img/days/" + games.day + ".svg";
    let pastDayImage =
      process.env.PUBLIC_URL + "/img/days/" + games.day + "-open.png";
    let hasParticipated = participation.includes(parseInt(games.day));
    ////////////
    //<Link to={todayDay == games.day ? "/" + games.game : "/"} onClick={start}>
    //<Link to={"/" + games.game+'?day='+games.day} onClick={start}></Link>
    ////////////

    return (
      <div
        key={index}
        className={
          "day-box " +
          games.pos +
          (hasParticipated == true || todayDay > games.day
            ? " past"
            : todayDay == games.day
            ? " today"
            : " future")
        }
        style={{ animation: animStr(index) }}>
        <Link to={"/" + games.game + "?day=" + games.day} onClick={start}>
          {hasParticipated == true ? (
            <img src={pastDayImage} alt={games.game} />
          ) : todayDay <= games.day ? (
            <SVG baseURL="/home" src={dayImage} />
          ) : (
            <img src={pastDayImage} alt={games.game} />
          )}
        </Link>
      </div>
    );
  };

  return (
    <>
      <Snowfall style={{ zIndex: "1" }} snowflakeCount={150} />
      <Container className="home">
        <Row className="px-3 justify-content-center">
          <Col className="col-md-7 p-0 ">
            <p className="lead text-center fw-bolder mb-0">
              24 sladkih skrivnosti in presenečenj za najbolj čaroben december.
              ﻿Vsak dan odpri novo polje decembrskega koledarja, odkrij dnevno
              skrivnost in se poteguj za sladke nagrade.
            </p>
          </Col>
        </Row>

        <Row className="advent-usp mt-4 px-2 justify-content-center">
          <Col className="col-4 col-md-3 p-0 text-center px-1">
            <Icon1 />
            <p className="text-center fs-6 fw-bolder">
              Vsak dan obišči naš koledar,
              <br />
              odpri novo okno in reši
              <br />
              zabavno igro.
            </p>
          </Col>
          <Col className="col-4 col-md-3 p-0 text-center px-1">
            <Icon2 />
            <p className="text-center fs-6 fw-bolder">
              Odkleni dnevno presenečenje
              <br />
              in vsak dan odkrij košček
              <br />
              skrivne slike.
            </p>
          </Col>
          <Col className="col-4 col-md-3 p-0 text-center px-1">
            <Icon3 />
            <p className="text-center fs-6 fw-bolder">
              Kolikor okenc odpreš,
              <br />
              tolikokrat sodeluješ v žrebu za
              <br />
              nagrado.
            </p>
          </Col>
        </Row>

        <div className="advent-days mt-2 mt-sm-4">
          {isLoading ? (
            <div className="text-center my-5">
              <Loader
                type="TailSpin"
                color="#B3122B"
                height={200}
                width={200}
                timeout={30000} //3 secs
              />
            </div>
          ) : (
            listOfGames
              .sort((a, b) => a.pos - b.pos)
              .map((games, index) => (
                <>
                  <ShowCalendar games={games} index={index} />
                </>
              ))
          )}
        </div>
      </Container>
    </>
  );
}

export default Advent;
