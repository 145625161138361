import React from 'react';
import Game from './Game';
import Finish from './Finish';
import useOptions from '../../utils/useOptions';
import Subscribe from "../../components/Subscribe";

function MemoryGame(props) {
  const [options, game, set, backgrounds] = useOptions();

  
  const component = {
    playing: (
      <Game {...set} {...options} {...game} />
      ),
    finished: (
      <Subscribe />

    ),
    options: (
      <Game {...set} {...options} {...game} />
    )
  }

  return component[game.status];
}

export default MemoryGame;
