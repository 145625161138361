import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import Cookies from "universal-cookie";
import { Row, Col, Button, Form } from "react-bootstrap";
import Thankyou from "./ThankYou";
import ReactGA from "react-ga";

var dateVariable = new Date();
var todayDay = dateVariable.getDate();

function SubscribeOptinNewsletter() {
  const [listOfUsers, setListOfUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [optinChecked, setOptinChecked] = useState(false);
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [finalstep, setFinalstep] = useState(false);
  const [isUniqueUser, setIsUniqueUser] = useState();
  const [isUniqueSubscriber, setIsUniqueSubscriber] = useState();

  var dateVariable = new Date();
  var todayDay = dateVariable.getDate();

  const cookies = new Cookies();
  const handleClickOptin = () => setOptinChecked(!optinChecked);
  const handleClickNewsletter = () => setNewsletterChecked(!newsletterChecked);

  let history = useHistory();

  const validateEmail = (email) => {
    Axios.get("https://lidl-koledar.escapebox.si/getUsers?email=" + email).then(
      (response) => {
        if (response.data.length == 0) {
          setIsUniqueUser(true);
        } else if (response.data[0].newsletterChecked == "false") {
          setIsUniqueSubscriber(true);
        }
      }
    );
  };

  //http://localhost:3001

  const createUser = () => {
    Axios.post("https://lidl-koledar.escapebox.si/createUser", {
      email: email,
      optinChecked: optinChecked,
      newsletterChecked: newsletterChecked,
      day: todayDay,
    }).then((response) => {
      setListOfUsers([
        ...listOfUsers,
        {
          email: email,
          optinChecked: optinChecked,
        },
      ]);

      cookies.set("user", email, { path: "/", secure: true, sameSite: "none" });
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      validateEmail(email);
      createUser();
      setFinalstep(true);
    }

    setValidated(true);
  };

  useEffect(() => {
    if (isUniqueUser && newsletterChecked) {
      ReactGA.event({
        category: "Form",
        action: "Opt-in",
      });

      ReactGA.event({
        category: "Form",
        action: "Newsletter",
      });
    }

    if (isUniqueUser && !newsletterChecked) {
      ReactGA.event({
        category: "Form",
        action: "Opt-in",
      });
    }
  }, [isUniqueUser, newsletterChecked]);

  useEffect(() => {
    if (isUniqueSubscriber) {
      ReactGA.event({
        category: "Form",
        action: "Newsletter",
      });
    }
  }, [isUniqueSubscriber]);

  return (
    <>
      {finalstep ? (
        <Thankyou />
      ) : (
        <div className="subscribe mt-3 px-4 py-5">
          <Row className=" gx-5 align-items-center">
            <Col className="col-12 col-md-5">
              <h2 className="display-6 fw-black text-white">
                Prijava v nagradno igro
              </h2>
              <p className="text-white">
                S prijavo se uvrstite v boben za tedenski žreb. Vsak teden bomo
                izžrebali 7 nagrajencev. Če boste uspešno odklenili več kot 10
                dnevnih skrivnosti, pa se uvrstite še v boben za žreb glavne
                nagrade.
              </p>
            </Col>
            <Col className="col-12 col-md-7 subscribe-form">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Elektronski naslov"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="text-secondary">
                    Prosimo vnesite elektronski naslov.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formOptin">
                  <Form.Check
                    required
                    onChange={handleClickOptin}
                    checked={optinChecked}
                    className=""
                    type="checkbox"
                    label={
                      <>
                        Strinjam se s{" "}
                        <a
                          href={
                            process.env.PUBLIC_URL +
                            "/files/Pravila_in_Pogoji_Lidl_koledar.pdf"
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="text-white">
                          pravili in pogoji
                        </a>{" "}
                        nagradne igre Decembrski koledar
                      </>
                    }
                    feedback="Za sodelovanje se morate strinjati s pogoji."
                    feedbackType="invalid"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNewsletter">
                  <Form.Label className="d-block text-white fs-6 fw-bold">
                    Se želite prijaviti tudi na naše e-novice?
                  </Form.Label>

                  <Form.Check
                    inline
                    label="DA"
                    name="newsletterOptin"
                    type="radio"
                    className="position-relative radio-inside"
                    id="formNewsletterOptinYes"
                    onChange={handleClickNewsletter}
                    checked={newsletterChecked}
                  />
                  <Form.Check
                    inline
                    label="NE"
                    name="newsletterOptin"
                    type="radio"
                    className="position-relative radio-inside"
                    id="formNewsletterOptinNo"
                  />
                </Form.Group>

                <Button
                  type="submit"
                  className={
                    "w-100 btn-answer btn-gradient-gold fw-bolder text-white "
                  }
                  size="lg">
                  SODELUJ V NAGRADNI IGRI
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default SubscribeOptinNewsletter;
