import React, {useState} from 'react';
import Card from '../components/MemoryGame/Card';
import sources from '../utils/sources';


function useOptions(status='options') {
  const [options, setOptions] = useState({bg:sources.bg[0]});
  const [game, setGame] = useState({status}); 

  function cardClick(i) {
    setOptions({...options, bg:sources.bg[i]});

  }

  const backgrounds = sources.bg.map((bg, i) => (
    <Card key={i} bg={bg}
      onClick={() => cardClick(i)} 
      selected={sources.bg[i] === options.bg}
    />
  ));

  const set = {
    start: () => {

      setGame({status: 'playing'});
    },
    stop: () => {

      setGame({status:'options'});
    },
    finish: errors => {

      setGame({status: 'finished', errors});
    }
  }

  return [options, game, set, backgrounds];
}

export default useOptions;