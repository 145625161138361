import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { getGameOnDayTEMP } from "../api/ApiCalls.js";
import Cookies from "universal-cookie";
import { Container, Row, Col, Image } from "react-bootstrap";
import SubscribeOptinNewsletter from "./SubscribeOptinNewsletter";
import SubscribeNewsletter from "./SubscribeNewsletter";
import SubscribeThankyou from "./SubscribeThankyou";
import Thankyou from "./ThankYou";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import FadeIn from "react-fade-in";
import ReactGA from "react-ga";

function Subscribe() {
  const { search } = useLocation();

  const [currentUser, setCurrentUser] = useState(false);
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const hasCookie = cookies.get("user");
  const [isLoading, setIsLoading] = useState(false);

  var dateVariable = new Date();
  var todayDay = dateVariable.getDate();

  const updateUser = () => {
    Axios.post("https://lidl-koledar.escapebox.si/createUser", {
      email: cookies.get("user"),
      day: todayDay,
    }).then((response) => {});
  };

  // Filter by saved cookie
  useEffect(() => {
    if (hasCookie) {
      Axios.get(
        "https://lidl-koledar.escapebox.si/getUsers?email=" + hasCookie
      ).then((response) => {
        if (
          response.data[0] !== undefined &&
          hasCookie == response.data[0].email &&
          response.data[0].newsletterChecked == "true"
        ) {
          setIsNewsletterChecked(true);
        } else {
          setCurrentUser(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    const dayParam = new URLSearchParams(search).get("day");
    setIsLoading(true);
    var config = { params: { day: 1 } };
    getGameOnDayTEMP(
      config,
      (res) => {
        setData(res.data);
        setIsLoading(false);
      },
      (err) => {
        //error
        console.log(err);
      }
    );
  }, []);

  function Form() {
    if (isNewsletterChecked) {
      updateUser();
      return <Thankyou />;
    } else if (currentUser) {
      updateUser();
      return <SubscribeNewsletter />;
    }

    return (
      <div className="text-center my-5">
        <Loader
          type="TailSpin"
          color="#D9B76B"
          height={100}
          width={100}
          timeout={30000} //3 secs
        />
      </div>
    );
  }

  //updateUser();
  // if(cookies.get('user'))

  return (
    <>
      {isLoading ? (
        <div className="text-center my-5">
          <Loader
            type="TailSpin"
            color="#D9B76B"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      ) : (
        <Container className="white-bg position-relative pb-4">
          <Row className="justify-content-center">
            <Col className="col-12 col-md-10 col-lg-8 col-xl-7 text-black">
              <h1 className="fw-black lh-1 text-gold-gradient display-3 text-center mt-5">
                Sladka skrivnost #{data.day}
              </h1>

              <Row className="mt-3 gx-md-5 align-items-center">
                {data.day === "24" ? (
                  <>
                    <Col className="col-12">
                      <p className="fs-5">{data.triviaTxt}</p>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="col-12 col-md-6">
                      <p className="fs-5">{data.triviaTxt}</p>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <Image
                        className="w-100"
                        src={
                          process.env.PUBLIC_URL + "/img/days/" + data.triviaPic
                        }
                      />
                    </Col>
                  </>
                )}
              </Row>

              {data.day === "24" && (
                <a
                  className={
                    "w-100 text-dark my-4 fw-bolder fs-6 btn btn-gradient-gold btn-lg"
                  }
                  href="/"
                  variant="light"
                  size="lg">
                  Poglej voščilo
                </a>
              )}

              {!hasCookie ? <SubscribeOptinNewsletter /> : <Form />}
            </Col>
          </Row>
          <img
            alt="BG1"
            className="bg-dec bg-dec__topleft"
            src={process.env.PUBLIC_URL + "/img/bg_top_left.svg"}
          />
          <img
            alt="BG2"
            className="bg-dec bg-dec__topright"
            src={process.env.PUBLIC_URL + "/img/bg_top_right.svg"}
          />
          <img
            alt="BG3"
            className="bg-dec bg-dec__bottomleft"
            src={process.env.PUBLIC_URL + "/img/bg_bottom_left.svg"}
          />
          <img
            alt="BG4"
            className="bg-dec bg-dec__bottomright"
            src={process.env.PUBLIC_URL + "/img/bg_bottom_right.svg"}
          />

          <img
            alt="BG5"
            className="bg-dec bg-dec__mobile"
            src={process.env.PUBLIC_URL + "/img/bg_top_mobile.svg"}
          />
        </Container>
      )}
    </>
  );
}

export default Subscribe;
