
import { useHistory } from "react-router-dom";



function Finish(props) {
  let history = useHistory();
  history.push('/subscribe')


}


export default Finish;