

import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import Confetti from 'react-dom-confetti';
import { getGameOnDayTEMP } from '../api/ApiCalls.js'


import iconCorrect from '../assets/img/icons/checkmark-svg.svg';
import iconWrong from '../assets/img/icons/error-svgrepo-com.svg';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import winSound from '../assets/sounds/win_Stage2.mp3';
import moveSound from '../assets/sounds/bite.mp3';
import wrongSound from '../assets/sounds/disable-sound.mp3';
import correctSound from '../assets/sounds/rising-pops.mp3';
import ReactGA from 'react-ga';

var dateVariable = new Date();
var todayDay = dateVariable.getDate();

function ClickAndFind() {

  const { search } = useLocation();
  let history = useHistory();

  const [data, setData] = useState([]);
  const [positions,setPositions] = useState([]);
  const [count, setCount] = useState(1);
  const [correct, setCorrect] = useState(false);
  const [clickposition, setClickposition] = useState({
    left: '',
    top: '',
    show: false
  })

  const [isLoading, setIsLoading] = useState(false);




  const audioWin = new Audio(winSound)
  const audioMove = new Audio(moveSound)
  const audioWrong = new Audio(wrongSound)
  const audioCorrect = new Audio(correctSound)


  useEffect(() => {
    const dayParam = new URLSearchParams(search).get("day");
    setIsLoading(true);
    var config = {params: {day: dayParam}}
    getGameOnDayTEMP(config, (res) => {

      setData(res.data);
      setPositions(res.data.positions)
      setIsLoading(false);
    },(err) => {
        //error
        console.log(err)
    });

  }, []);




  const imageClick = (e) => {


    var rect = e.target.getBoundingClientRect();

    var absLeft = (e.clientX - rect.left) * 94 / rect.width;
    var absTop = (e.clientY - rect.top) * 94 / rect.height;


    if(!e.target.className.includes("item") && !e.target.className.includes("wrong") ) {
      setClickposition({...clickposition, left: absLeft, top: absTop, show: true});

      setTimeout(() => {
        setClickposition({...clickposition, left: absLeft, top: absTop, show: false});
      }, 500);

      audioWrong.play()

    }

    //console.log(absTop,absLeft);


  };



  const addPoint = (event) => {

    const found = event.target.id;
    var element = document.getElementById(found);

    if(!found.includes("found")) {

      setCount(count + 1)
      element.classList.add("found");

      if(count === 5) {
        setCorrect(true);
        audioWin.play()
        ReactGA.event({
          category: 'Igra',
          action: 'win',
          label: 'dan-'+todayDay
        });


        setTimeout(() => {
          history.push('/subscribe')
        }, 2000);
      }

      audioCorrect.play()

    }

  };

  
  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 70,
    elementCount:100,
    dragFriction: 0.10,
    duration: 2000,
    stagger: 1,
    width: "15px",
    height: "15px",
    perspective: "500px",
    colors: ["#AD8B44", "#A68A4D", "#EB193A", "#AC1129", "#AC1129"]
  };




  return (

    <>
      {isLoading ? (
          <div className="text-center my-5"><Loader
          type="TailSpin"
          color="#D9B76B"
          height={100}
          width={100}
          timeout={30000} //3 secs
        /></div>
      ) : (

    <Container className="white-bg position-relative pb-4">

      <Row className="justify-content-center">
        <Col className="col-12 col-xl-7 px-2 text-black">
          <h1 className="fw-black lh-1 text-gold-gradient display-3 text-center mt-4">{data.xtratext}</h1>
          <p className="lead text-center mt-4 mb-5 my-sm-5">{data.question}</p>

            

            <div className="text-center">
              <div className="imageClickAndFind" onMouseDown={imageClick}>
                <div className={`cross ${clickposition.show ? 'x-shown' : 'x-hidden'}`} style={{top: clickposition.top+"%" , left: clickposition.left+"%"}}>

                <img className="wrong" alt="wrong" src={iconWrong} /></div>
                <img src={process.env.PUBLIC_URL +"/img/days/"+data.gamePic} alt={data.game} />

                {positions.map((tag, index) => {

                  return (
                    <img 
                    onClick={addPoint} 
                    key={index} 
                    style={{top: tag.top, left: tag.left}} 
                    className={'item'} 
                    id={index}
                    src={iconCorrect} 
                    alt={"point_"+index}

                    />

                  );
                })}

                <Confetti className="confetti" active={ correct } config={ config }/>
              </div>
            </div>


            
                
        </Col>
      </Row>
      <img alt="BG1" className="bg-dec bg-dec__topleft" src={process.env.PUBLIC_URL +"/img/bg_top_left.svg"} />
      <img alt="BG2" className="bg-dec bg-dec__topright" src={process.env.PUBLIC_URL +"/img/bg_top_right.svg"} />
      <img alt="BG3" className="bg-dec bg-dec__bottomleft" src={process.env.PUBLIC_URL +"/img/bg_bottom_left.svg"} />
      <img alt="BG4" className="bg-dec bg-dec__bottomright" src={process.env.PUBLIC_URL +"/img/bg_bottom_right.svg"} />

      <img alt="BG5" className="bg-dec bg-dec__mobile" src={process.env.PUBLIC_URL +"/img/bg_top_mobile.svg"} />
    </Container>

    )}
    </>


  )

}

export default ClickAndFind;
