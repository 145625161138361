import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import PuzzleGame from './PuzzleGame/Puzzle';
import {Container, Row, Col} from 'react-bootstrap';
import Confetti from 'react-dom-confetti';
import { getGameOnDayTEMP } from '../api/ApiCalls.js'

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactGA from 'react-ga';




function Puzzle() {
  const { search } = useLocation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const dayParam = new URLSearchParams(search).get("day");
    setIsLoading(true);
    var config = {params: {day: dayParam}}
    getGameOnDayTEMP(config, (res) => {
      setData(res.data);
      setIsLoading(false);
    },(err) => {
        //error
        console.log(err)
    });

  }, []);


  return (


    <>
    {isLoading ? (
        <div className="text-center my-5"><Loader
        type="TailSpin"
        color="#D9B76B"
        height={100}
        width={100}
        timeout={30000} //3 secs
      /></div>
    ) : (

    <Container className="white-bg pb-4 position-relative">

      <Row className="justify-content-center">
        <Col className="col-12 col-xl-7 text-black">
          <h1 className="fw-black text-gold-gradient display-3 text-center mt-4">{data.xtratext}</h1>
          <p className="lead text-center my-5">{data.question}</p>


            <PuzzleGame
            image={process.env.PUBLIC_URL +"/img/days/"+data.gamePic}
          />
          



        </Col>
      </Row>

      <img alt="BG1" className="bg-dec bg-dec__topleft" src={process.env.PUBLIC_URL +"/img/bg_top_left.svg"} />
      <img alt="BG2" className="bg-dec bg-dec__topright" src={process.env.PUBLIC_URL +"/img/bg_top_right.svg"} />
      <img alt="BG3" className="bg-dec bg-dec__bottomleft" src={process.env.PUBLIC_URL +"/img/bg_bottom_left.svg"} />
      <img alt="BG4" className="bg-dec bg-dec__bottomright" src={process.env.PUBLIC_URL +"/img/bg_bottom_right.svg"} />

      <img alt="BG5" className="bg-dec bg-dec__mobile" src={process.env.PUBLIC_URL +"/img/bg_top_mobile.svg"} />

    </Container>
    )}
    </>



  )

}

export default Puzzle;
