import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import Cookies from "universal-cookie";
import { Row, Col, Button, Form } from "react-bootstrap";
import SubscribeThankyou from "./SubscribeThankyou";
import ReactGA from "react-ga";

function SubscribeNewsletter() {
  const [listOfUsers, setListOfUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [finalstep, setFinalstep] = useState(false);
  const [isUniqueSubscriber, setIsUniqueSubscriber] = useState();

  var dateVariable = new Date();
  var todayDay = dateVariable.getDate();
  const cookies = new Cookies();

  const handleClickNewsletter = () => setNewsletterChecked(!newsletterChecked);

  let history = useHistory();

  const validateEmail = (email) => {
    Axios.get("https://lidl-koledar.escapebox.si/getUsers?email=" + email).then(
      (response) => {
        if (response.data[0].newsletterChecked == "false") {
          setIsUniqueSubscriber(true);
        }
      }
    );
  };

  const updateUser = () => {
    Axios.post("https://lidl-koledar.escapebox.si/createUser", {
      email: cookies.get("user"),
      newsletterChecked: newsletterChecked,
      day: todayDay,
    }).then((response) => {});
  };

  const handleSubmitUpdate = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      //console.log(cookies.get('user'))
      validateEmail(cookies.get("user"));
      updateUser();
      setFinalstep(true);
    }

    setValidated(true);
  };

  useEffect(() => {
    if (isUniqueSubscriber && newsletterChecked) {
      ReactGA.event({
        category: "Form",
        action: "Newsletter",
      });
    }
  }, [isUniqueSubscriber, newsletterChecked]);

  return (
    <>
      {finalstep ? (
        <SubscribeThankyou />
      ) : (
        <div
          className={`subscribe mt-3 px-4 py-5 ${
            finalstep && "subscribe__fadeout"
          }`}>
          <Row className=" gx-5 align-items-center">
            <Col className="col-12 col-md-6">
              <h2 className={"display-6 fw-black text-white"}>
                Prijava v danšanjo nagradno igro je uspešna!
              </h2>
              <p className="text-white">
                Ne pozabi na novo okence jutri. Le kaj se skriva pod njim? Tudi
                nas že pošteno srbijo prsti.
              </p>
            </Col>
            <Col className="col-12 col-md-6 subscribe-form">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmitUpdate}>
                <Form.Group
                  className="mb-3 text-center"
                  controlId="formNewsletter">
                  <Form.Label className="d-block text-white fs-4 fw-bold">
                    Se želite prijaviti tudi na naše e-novice?
                  </Form.Label>

                  <Form.Check
                    required
                    inline
                    label="DA"
                    name="newsletterOptin"
                    type="radio"
                    className="position-relative radio-inside"
                    id="formNewsletterOptinYes"
                    onChange={handleClickNewsletter}
                    checked={newsletterChecked}
                    feedback="Za sodelovanje se morate strinjati s pogoji."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    inline
                    label="NE"
                    name="newsletterOptin"
                    type="radio"
                    className="position-relative radio-inside"
                    id="formNewsletterOptinNo"
                  />
                </Form.Group>

                <Button
                  type="submit"
                  className={
                    "w-100 btn-answer btn-gradient-gold fw-bolder text-white "
                  }
                  size="lg">
                  PRIJAVA NA E-NOVICE
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default SubscribeNewsletter;
