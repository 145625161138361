import { useState, useRef } from "react";
import {Row, Col, Button, Overlay, Tooltip, OverlayTrigger, Popover} from 'react-bootstrap';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

import ReactGA from 'react-ga';

var dateVariable = new Date();
var todayDay = dateVariable.getDate();

function SubscribeThankyou() {

  const [show, setShow] = useState(false);
  const target = useRef(null);


  const toClipboard = () =>  {
    navigator.clipboard.writeText("https://www.lidl.si");
    
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 1000);
    ReactGA.event({
      category: 'CTA',
      action: 'copy',
    });
  }

  const share = () => {

    ReactGA.event({
      category: 'CTA',
      action: 'share',
    });
  }


  return (


      <>

        <div className="subscribe mt-3 px-4 py-5">
          <Row className=" gx-5 align-items-center">
            <Col className="col-12 col-md-6">
              <h1 className="fw-black text-left lh-1 text-white display-6">Prijava na naše e-novice uspešna!</h1>

              <p className="text-left fs-5 my-3 text-white">Dobrodošli v Lidlovem svetu fantastičnih ponudb in zanimivih vsebin.</p>
            </Col>
            <Col className="col-12 col-md-6 subscribe-form">
              <Row className=" gx-5 align-items-center">
                <Col className="col-12">
                  <h2 className="display-6 text-center fw-black text-white mb-3">Povejte naprej</h2>

                  <Row>
                    <Col className="col-6">
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={
                        <Popover id="share">
                          <Popover.Body>
                            <FacebookShareButton
                                url="https://www.lidl.si/favorina/decemberski-koledar-sladkih-skrivnosti"
                                >
                                <FacebookIcon round="true" size="40" />
                            </FacebookShareButton>

                            <LinkedinShareButton
                                url="https://www.lidl.si/favorina/decemberski-koledar-sladkih-skrivnosti"
                                >
                                <LinkedinIcon round="true" size="40" />
                            </LinkedinShareButton>

                            <TwitterShareButton
                                url="https://www.lidl.si/favorina/decemberski-koledar-sladkih-skrivnosti"
                                >
                                <TwitterIcon round="true" size="40"/>
                            </TwitterShareButton>

                          </Popover.Body>
                        </Popover>
                      }
                    >

                        <Button 
                        className={"w-100 btn-answer btn-gradient-gold fw-bolder text-white icon-share"}
                        size="sm"
                        onClick={share}
                        >
                        Deli
                        </Button>
                    </OverlayTrigger>

                    </Col>
                    <Col className="col-6">
                      <Button 
                      className={"w-100 btn-answer btn-gradient-gold fw-bolder text-white icon-copy"}
                      size="sm"
                      ref={target}
                      onClick={toClipboard}
                      >
                      Kopiraj
                      </Button>
                      <Overlay target={target.current} show={show} placement="top">
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            Shranjeno
                          </Tooltip>
                        )}
                      </Overlay>


                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="col-12">
                     <a 
                      className={"w-100 text-dark fw-bolder fs-6 btn btn-light btn-lg"}
                      href="https://www.lidl.si/favorina"
                      target="_blank"
                      variant="light"
                      onClick={() => ReactGA.event({category: 'CTA', action: 'ponudba'})}
                      size="lg"
                      >
                      Na sladko ponudbo
                      </a>
                    </Col>
                  </Row>



                </Col>

              </Row>
            </Col>
          </Row>
        </div>



        </>



  )
}



export default SubscribeThankyou;
