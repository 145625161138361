import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'; // 
import { withRouter } from 'react-router-dom';
import Confetti from 'react-dom-confetti';



import winSound from '../../assets/sounds/win_stage_igra.mp3';
import moveSound from '../../assets/sounds/bite.mp3';
import ReactGA from 'react-ga';

var dateVariable = new Date();
var todayDay = dateVariable.getDate();
  
const config = {
  angle: 90,
  spread: 45,
  startVelocity: 70,
  elementCount:100,
  dragFriction: 0.10,
  duration: 2000,
  stagger: 1,
  width: "15px",
  height: "15px",
  perspective: "500px",
  colors: ["#AD8B44", "#A68A4D", "#EB193A", "#AC1129", "#AC1129"]
};



/**
 * Shuffles the passed array and returns a new one
 *
 * @param  {Array} a
 * @return {Array}
 */
function shuffle(a) {
  const b = a.slice();

  for (let i = b.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [b[i], b[j]] = [b[j], b[i]];
  }

  return b;
}



class Puzzle extends React.Component {
  constructor(props) {
    super(props);

    const { level } = props;
    const cells = level * level;

    this.state = { positions: [...Array(cells).keys()], showConfeti: false };



  }




  componentDidMount() {
    const { positions } = this.state;

    this.setState({ positions: shuffle(positions) });


  }

  onSwap(sourcePosition, dropPosition) {

    let audioMove = new Audio(moveSound)
    let audioWin = new Audio(winSound)

    audioMove.play()


    const oldPositions = this.state.positions.slice();
    const newPositions = [];
    let done = true;
    let p = 0;


    for (let i in oldPositions) {
      let value = oldPositions[i];
      let newValue = value;

      if (value === sourcePosition) {
        newValue = dropPosition;
      } else if (value === dropPosition) {
        newValue = sourcePosition;
      }

      newPositions.push(newValue);

      if (newValue !== p) {
        done = false;
      }

      p = p + 1;
    }

    this.setState({ positions: newPositions });

    if(done) {

      audioWin.play()
      this.props.onDone();
      this.setState(state => ({ showConfeti: true }));
      ReactGA.event({
        category: 'Igra',
        action: 'win',
        label: 'dan-'+todayDay
      });


      setTimeout(() => {
        this.props.history.push("/subscribe")
      }, 2000);




    }
  }

  renderSquares() {
    const { image, size, level } = this.props;
    const { positions } = this.state;

    const squares = positions.map((i) => {
      return (
        <Cell
          key={i}
          size={size}
          image={image}
          level={level}
          position={i}
          onSwap={this.onSwap.bind(this)}
        />
      );
    })

    return squares;
  }

  render() {
    const { size, done } = this.props;


    return (
      <div
        className={"puzzle-container " + this.state.showConfeti}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: 0,
          width: `${size}px`,
          height: `${size}px`,
          margin: '0 auto'
        }}>
        {this.renderSquares()}
        <Confetti className="confetti" active={ this.state.showConfeti } config={ config }/>
      </div>

    );
  }





};



Puzzle.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.number,
  level: PropTypes.number,
  onDone: PropTypes.func,
};

Puzzle.defaultProps = {
  size: 600,
  level: 3,
  onDone: () => {},
};




export default withRouter(DragDropContext(MultiBackend(HTML5toTouch))(Puzzle));