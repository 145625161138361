import React, {useEffect} from 'react';
import useGame from '../../utils/useGame';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'react-bootstrap';
import Confetti from 'react-dom-confetti';

import winSound from '../../assets/sounds/win_stage_igra.mp3';
import ReactGA from 'react-ga';
import Snowfall from 'react-snowfall'

var dateVariable = new Date();
var todayDay = dateVariable.getDate();

function Game(props) {
  const {bg, stop, finish} = props;
  const [cards, reshuffle, errors, finished] = useGame(bg);
  const audioWin = new Audio(winSound)


  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 70,
    elementCount:100,
    dragFriction: 0.10,
    duration: 2000,
    stagger: 1,
    width: "15px",
    height: "15px",
    perspective: "500px",
    colors: ["#AD8B44", "#A68A4D", "#EB193A", "#AC1129", "#AC1129"]
  };
  


  useEffect(() => {
    if (finished)  {

      audioWin.play();

      ReactGA.event({
        category: 'Igra',
        action: 'win',
        label: 'dan-'+todayDay
      });


      setTimeout(() => {
          finish(errors);
      }, 2000);
    }


  }, [finished]);



  return (


    <Container className="white-bg position-relative pb-4">

      <Row className="justify-content-center">
        <Col className="col-12 col-xl-9 px-2 text-black">
          <h1 className="fw-black lh-1 text-gold-gradient display-3 text-center mt-4">Kako pa kaj spomin?</h1>
          <p className="lead text-center mt-4 mb-5 my-sm-5">Odkrijte vse pare in poglejte, kakšna<br />modrost je današanja skrivnost.</p>


          
          <main className={"game " + bg}>

            <div className="deck fadein">
              {cards}
              <Confetti className="confetti" active={ finished } config={ config }/>
            </div>
            {/* <button onClick={() => finish(errors)}>Finish</button> */}
            
          </main>



        </Col>
      </Row>

      <img alt="BG1" className="bg-dec bg-dec__topleft" src={process.env.PUBLIC_URL +"/img/bg_top_left.svg"} />
      <img alt="BG2" className="bg-dec bg-dec__topright" src={process.env.PUBLIC_URL +"/img/bg_top_right.svg"} />
      <img alt="BG3" className="bg-dec bg-dec__bottomleft" src={process.env.PUBLIC_URL +"/img/bg_bottom_left.svg"} />
      <img alt="BG4" className="bg-dec bg-dec__bottomright" src={process.env.PUBLIC_URL +"/img/bg_bottom_right.svg"} />

      <img alt="BG5" className="bg-dec bg-dec__mobile" src={process.env.PUBLIC_URL +"/img/bg_top_mobile.svg"} />

    </Container>






  )
}

Game.propTypes = {
  bg: PropTypes.string.isRequired,
  stop: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
}

export default Game;