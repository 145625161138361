import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getGameOnDayTEMP } from '../api/ApiCalls.js'
import {Container, Row, Col, Button, Image, Tooltip, OverlayTrigger} from 'react-bootstrap';
import Confetti from 'react-dom-confetti';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import useSound from 'use-sound';

import wrongSound from '../assets/sounds/disable-sound.mp3';
import winSound from '../assets/sounds/win_stage_igra.mp3';
import ReactGA from 'react-ga';

var dateVariable = new Date();
var todayDay = dateVariable.getDate();



function Quiz() {

  let history = useHistory();


  const [data, setData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [correct, setCorrect] = useState(false)
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [playWrong] = useSound(wrongSound);
  const [playWin] = useSound(winSound);


  const { search } = useLocation();



  useEffect(() => {
    const dayParam = new URLSearchParams(search).get("day");

    setIsLoading(true);
    var config = {params: {day: dayParam}}
    getGameOnDayTEMP(config, (res) => {
      
      console.log("response", res);
      setData(res.data);
      setAnswers(res.data.answerOptions)
      setIsLoading(false);

      
    },(err) => {
        //error
        console.log(err)
    });

  }, []);

  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 70,
    elementCount:100,
    dragFriction: 0.10,
    duration: 2000,
    stagger: 1,
    width: "15px",
    height: "15px",
    perspective: "500px",
    colors: ["#AD8B44", "#A68A4D", "#EB193A", "#AC1129", "#AC1129"]
  };


  const handleAnswerOptionClick = (e, isCorrect) => {


    const id = e.target.id;
    var element = document.getElementById(id);

    if (isCorrect) {
      element.classList.add("correct");
      setCorrect(true);
      playWin();
      ReactGA.event({
        category: 'Igra',
        action: 'win',
        label: 'dan-'+todayDay
      });
      
      setTimeout(() => {
        setScore(score + 1);
        history.push('/subscribe')
      }, 2000);

    } else {
      element.classList.add("wrong");
      setShow(true)
      playWrong();
    }

  };




  return (


      <>
      {isLoading ? (
          <div className="text-center my-5"><Loader
          type="TailSpin"
          color="#D9B76B"
          height={100}
          width={100}
          timeout={30000} //3 secs
        /></div>
      ) : (
      <Container className="white-bg position-relative pb-4">

        <Row className="px-3 justify-content-center">
        



          <Col className="col-12 col-lg-10 col-xl-6 px-2 text-center text-black">
            <h1 className="fw-black lh-1 text-gold-gradient display-3">{data.xtratext}</h1>
            <p className="text-center fs-5"> {data.question}</p>
            <hr class="my-4" />


              <>
                <div className='question-section'>

                  <p className='question-text text-center fw-bolder fs-4'>{data.questionText}</p>

                  {data.video ? (

                  <div className='embed-container my-3'>

                  <iframe src={'https://www.youtube.com/embed/'+data.gamePic+'?rel=0?start=225&end=268&modestbranding=0&autohide=1&showinfo=0&controls=0'} frameborder="0" allowfullscreen></iframe>



                  </div>

                  ) : (


                  <Image className="w-100 mb-2" src={process.env.PUBLIC_URL +"/img/days/"+data.gamePic} />

                  )}

                  


                </div>
                <div className='answer-section'>
                  <Row>
                  {answers.map((answerOption,index) => (

                    <Col className="col-12 col-sm-6">



                    <OverlayTrigger

                      trigger="click"
                      delay={{ hide: 400 }}
                      key={index}
                      rootClose={true}
                      placement="top"
                      overlay={
                          <Tooltip className={ answerOption.isCorrect && "d-none"} id={index}>
                            Napačen odgovor, poskusi še enkrat.
                          </Tooltip>
                      }
                    >

                      <Button 
                      className={"w-100 my-1 my-md-2 mx-md-2 btn-answer btn-gradient-red text-white"}
                      variant="primary" 
                      size="lg"
                      id={"answer_" + index}
                      key={index} 
                      onClick={(e, isCorrect) => handleAnswerOptionClick(e, answerOption.isCorrect)}>{answerOption.answerText}

                      </Button>

                    </OverlayTrigger>


                    </Col>

                  ))}

                  </Row>
                </div>
                <Confetti className="confetti" active={ correct } config={ config }/>
              </>




          </Col>






        </Row>


        <img alt="BG1" className="bg-dec bg-dec__topleft" src={process.env.PUBLIC_URL +"/img/bg_top_left.svg"} />
        <img alt="BG2" className="bg-dec bg-dec__topright" src={process.env.PUBLIC_URL +"/img/bg_top_right.svg"} />
        <img alt="BG3" className="bg-dec bg-dec__bottomleft" src={process.env.PUBLIC_URL +"/img/bg_bottom_left.svg"} />
        <img alt="BG4" className="bg-dec bg-dec__bottomright" src={process.env.PUBLIC_URL +"/img/bg_bottom_right.svg"} />

        <img alt="BG5" className="bg-dec bg-dec__mobile" src={process.env.PUBLIC_URL +"/img/bg_top_mobile.svg"} />

        </Container>

        )}
        </>



  );

}

export default Quiz;
